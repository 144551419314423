/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

      var Navigation = (function($, window, document, undefined) {
        return{
          navWrapper: $('#topNav'),
          utilityContainer: $('#topNav .utility'),
          navContainer: $('#topNav .navbar'),
          navMenu: $('#navMenu'),
          toggleSwitch: $('#toggleNav'),
          open: ! $('#toggleNav').hasClass('collapsed'),
          hasBackground: false,
          backgroundOffset: 50,

          /**
           * Open the mobile navigation menu.
           *
           * When run, this function will slide the mobile navigation menu in from
           * its position outside of the viewport. It is important that the
           * boolean value of open is switched, as this boolean keeps track of
           * what function to run when the toggleSwitch is clicked.
           */
          openMenu: function(duration) {
              var duration = (duration >= 0) ? duration : 500;

              this.toggleSwitch.removeClass('collapsed');
              this.navMenu.animate({'left': '0'}, duration);
              this.navMenu.css('left', '0!important');
              this.open = !this.open;
          },

          /**
           * Close the mobile navigation menu
           *
           * See openMenu for a detailed description.
           */
          closeMenu: function(duration) {
              var duration = (duration >= 0) ? duration : 500;

              this.toggleSwitch.addClass('collapsed');
              this.navMenu.animate({'left': '-100%'}, duration);
              this.navMenu.css('left', '-100%!important');
              this.open = !this.open;
          },

          /**
           * Add a background style to the navigation bar at a specified scroll
           * position.
           *
           * When the window scroll position is greater than the value of
           * backgroundOffset, the 'background' class is added to the navigation
           * container with a 500 millisecond jQuery UI animation. It is up to the
           * CSS to dictate what this style looks like.
           *
           * It is important that the property hasBackground is properly set, to
           * prevent the function from animating the background class on each
           * pixel scrolled, which would cause serious rendering and page
           * performance problems.
           */
          toggleBackground: function() {
              if ($(window).scrollTop() > this.backgroundOffset && !this.hasBackground) {
                  this.navContainer.addClass('navbar-scrolled', 500);
                  this.utilityContainer.slideUp(500);
                  this.hasBackground = true;

              } else if ($(window).scrollTop() <= this.backgroundOffset && this.hasBackground) {
                  this.navContainer.removeClass('navbar-scrolled', 500);
                  this.utilityContainer.slideDown(500);
                  this.hasBackground = false;
              }
          },

          /**
           * This is the only method that needs to be called, it will add the
           * required listeners and logic needed to handle all aspects of the
           * navigation menu
           */
          init: function() {
              var self = this;
              // console.log('loaded init');

              // Listen for navigation toggle
              self.toggleSwitch.click(function() {
                // console.log('clicked yo');
                  if (self.open) {
                      self.closeMenu();
                  } else {
                      self.openMenu();
                  }
              });

              // If window is resized, reset styles
              $(window).resize(function() {
                  if (self.open && $(window).width() < 768) {
                      self.closeMenu(0);
                  } else if (! self.open && $(window).width() > 768) {
                      self.openMenu(0);
                  }
              });

              // Add background to fixed navigation if page is scrolled
              self.toggleBackground();
              $(window).scroll(function() {
                  self.toggleBackground();
              });
          }
        };
      })(jQuery, window, document);



        // Initialize the navigation menu
        Navigation.init();


        // JavaScript to be fired on all pages
                $('.plan-your-project').click(function(event){
                    event.preventDefault();
                    console.log('plan-yourproject');
                    $( ".project-area" ).slideToggle( "250" );
                });

                $('a[data-scroll]').click(function(event) {

                    console.log('Data Scroll');

                    position  = $("#"+$(this).data("scroll")).offset().top;
                    position  = position-100;

                    $('html, body').animate({
                        scrollTop: position
                    }, 1000);
                });
    
                $.everydayImInstagrammin = {
                    defaults: {
                        clientID: "null",
                        accessToken: "null",
                        numberPics: "12",
                        imgClass: "everyday-img",
                        fadeInTime: "8500",
                        sequenceFadeIn: "true",
                        captions: "false",
                        captionAlign: "bottom",
                        cappy: "false"
                    }
                }, $.fn.extend({
                    everydayImInstagrammin: function(t) {
                        return $.extend($.everydayImInstagrammin.defaults, t), this.each(function() {
                            var e = $(this),
                                n = t.clientID,
                                i = t.accessToken,
                                o = t.numberPics,
                                r = t.liClass,
                                a = t.imgClass,
                                s = t.sequenceFadeIn,
                                l = t.captionAlign,
                                c = "https://api.instagram.com/v1/users/1523090036/media/recent/?access_token=" + i + "&callback=?";
                            $.ajax({
                                type: "GET",
                                dataType: "jsonp",
                                cache: !1,
                                url: c,
                                success: function(n) {
                                    for (var i = 0; i < t.numberPics; i++)
                                        if (n.data[i].hasOwnProperty("caption")) {
                                            var o = "";
                                            null !== n.data[i].caption && (o = n.data[i].caption.text), e.append("<li class='everyday-item'><a target='_blank' href='" + n.data[i].link + "'><img class='" + t.imgClass + "' src='" + n.data[i].images.standard_resolution.url + "' /><div class='everyday-caption'><p>" + o + "</p></div></a></li>")
                                        } else e.append("<li class='everyday-item'><a target='_blank' href='" + n.data[i].link + "'><img class='" + t.imgClass + "' src='" + n.data[i].images.standard_resolution.url + "'  /></a></li>");
                                    if ("bottom" == t.captionAlign && $(".everyday-caption p").css({
                                            position: "absolute",
                                            bottom: "10px"
                                        }), "top" == t.captionAlign && $(".everyday-caption p").css({
                                            position: "absolute",
                                            top: "10px"
                                        }), t.sequenceFadeIn = "true") {
                                        var r = 0;
                                        $("img.insta-img").hide().each(function() {
                                            $(this).delay(r).fadeIn(1e3), r += 350
                                        })
                                    }
                                    $("li.everyday-item a").on({
                                        mouseenter: function() {
                                            // $(".everyday-caption", this).filter(":not(:animated)").fadeIn(400)
                                        },
                                        mouseleave: function() {
                                            // $(".everyday-caption", this).fadeOut(400)
                                        }
                                    })
                                }
                            })
                        })
                    }
                  });
                
            $(".instagram").everydayImInstagrammin({
                clientID: "42602858",
                accessToken: "42602858.1677ed0.8fa5d842a44f47e5bd809ffdcc204446",
                numberPics: "8",
                imgClass: "insta-img",
                sequenceFadeIn: "false",
                captions: "false",
                captionAlign: "bottom",
                fadeInTime: "5000"
            });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
